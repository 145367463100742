/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function() {
				// JavaScript to be fired on all pages

				$('.popular-grid').packery({
					gutter: '.gutter-sizer',
					itemSelector: '.tile',
					percentPosition: true
				});

				// Subscribe modal
				$('.subscribe a').on('click', function(event){
					event.preventDefault();
					$('#subscribe-modal').modal('show');
				});

				// Share Story modal
				$('.sharestory-modal-link').on('click', function(event){
					event.preventDefault();
					$this = $(this);
					$target = $this.data('target');
					$($target).modal('show');
				});

				// Volunteer forms
				$('.btn-volunteer-email').on('click', function(){
					$this = $(this);
					$modalID = $this.data('modal-id');
					$modal = $('#' + $modalID);
					$volunteer_email = $this.data('volunteer-email');

					$modal.find('.volunteer-form').html('<form id="mktoForm_10187" class="' + $modalID + '"></form>');
					$modal.find('.volunteer-bio').hide();

					$modal.find('.volunteer-connect').show('500', function(){
						MktoForms2.loadForm("//app-lon04.marketo.com", "087-KYD-238", 10187, function(form){
							form.vals({ 'volunteerEmailAddress': $volunteer_email });
							// form.onSuccess(function(values, followUpUrl){
								
							// 	return false;
							// });
						});
					});
				});

				$('.modal-volunteer').on('hidden.bs.modal', function(event){
					$this = $(this);
					$this.find('.volunteer-form').empty();
					$this.find('.volunteer-connect').hide();
					$this.find('.volunteer-bio').show();
				});

			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'home': {
			init: function() {
				// JavaScript to be fired on the home page

				// Packery Grid
				$('.home-grid').packery({
					gutter: '.gutter-sizer',
					itemSelector: '.tile',
					percentPosition: true,
					stamp: '.stamp'
				});

				// Social Feed scroll
				$('.social-feeds .scrollbar-dynamic').scrollbar();

				// Social Feed Tabs
				$('#social-feed-tabs a').on('click', function(event){
					event.preventDefault();
					$(this).tab('show');
				});

			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		'single_post': {
			init: function() {
				// JavaScript to be fired on Single Posts

				// Open external links in a new window
				$('.main a')
					.filter('[href^="http"], [href^="//"]')
					.not('[href*="' + window.location.host + '"]')
					.attr('rel', 'noopener noreferrer')
					.attr('target', '_blank');

				// Modals
				$('.volunteer-modal-link').on('click', function(event){
					event.preventDefault();
					$this = $(this);
					$target = $this.data('target');

					$($target).modal('show');
				});

				// Modals from Modal
				$('.more-volunteer-item > a').on('click', function(event){
					event.preventDefault();
					$this = $(this);
					$target = $this.data('volunteer');

					window.location = '/our-volunteers/?id=' + $target;
				});

				// Social Feed scroll
				$('.scrollbar-dynamic').scrollbar();

			}
		},
		// Category archive
		'category': {
			init: function() {
				// JavaScript to be fired on Category archive pages

				$('.category-grid').packery({
					gutter: '.gutter-sizer',
					itemSelector: '.tile',
					percentPosition: true,
				});
			}
		},
		// Volunteer archive page
		'post_type_archive_volunteer': {
			init: function() {
				// JavaScript to be fired on the Volunteer archive

				// Init Mixitup
				var mixer = mixitup('.volunteer-grid', {
					selectors: {
						target: '.tile'
					},
					multifilter: {
						enable: true
					}
				});

				// Modals
				$('.volunteer-modal-link').on('click', function(event){
					event.preventDefault();
					$this = $(this);
					$target = $this.data('target');

					$($target).modal('show');
				});

				// Modals from Modal
				$('.more-volunteer-item > a').on('click', function(event){
					event.preventDefault();
					$this = $(this);
					$target = $this.data('target');

					var $open_modals = $('.modal.in');
					if($open_modals){
						$open_modals.modal('hide');
					}

					setTimeout(function(){
						$($target).modal('show');
					}, 500);
				});

				// Modal launch via query var
				var getUrlParameter = function getUrlParameter(sParam) {
					var sPageURL = decodeURIComponent(window.location.search.substring(1)),
						sURLVariables = sPageURL.split('&'),
						sParameterName,
						i;
					for (i = 0; i < sURLVariables.length; i++) {
						sParameterName = sURLVariables[i].split('=');
						if (sParameterName[0] === sParam) {
							return sParameterName[1] === undefined ? true : sParameterName[1];
						}
					}
				};
				// if query var exists, launch popup
				var id_query = getUrlParameter('id');
				if(id_query){
					$('#modal-volunteer-' + id_query).modal('show');
				}

			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
